<template>
	<v-card flat color="transparent" width="100%">
		<v-combobox
			:label="label"
			clearable
			:multiple="multiple"
			outlined
			:items="items"
			:value="selected"
			item-value="id"
			item-text="name"
			:return-object="false"
			@input="selectOrCreate"
		>
			<template v-slot:selection="{ item }">
				<v-chip
					v-if="item"
					:color="item.color || 'primary'"
					router-link
					target="_blank"
					:to="`/${storeModule}/${item.id}`"
					>{{ item.name }}</v-chip
				>
			</template>
		</v-combobox>
		<v-dialog v-model="dialog" :width="360">
			<v-card>
				<v-card-title> {{ $t("misc.are_you_sure") }} </v-card-title>
				<v-card-text>
					This action will add a new object to the db
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="close" text>{{ $t("buttons.cancel") }}</v-btn>
					<v-btn @click="confirm" color="primary">{{
						$t("buttons.submit")
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<style lang="less" scoped>
.v-chip {
	cursor: pointer;
}
</style>

<script type="text/javascript">
import Vuex from "vuex";

export default {
	name: "SelectOrCreate",
	props: {
		value: {}, // @doc the intial value
		storeModule: { type: String }, // the module of the store to select from and add to
		include: { type: Array }, // an array of IDs to include
		exclude: { type: Array }, // an array of IDs to exclude,
		label: { type: String }, //
		multiple: { type: Boolean, default: true }, // whether to allow multiple answers
		itemText: { type: String, default: "name" }, // the key to use for the item label
		defaultValues: { type: Object }, // any default values to include with the newly added object
	},
	data: () => {
		return {
			latest: "",
			dialog: false,
		};
	},
	computed: {
		...Vuex.mapState({
			collection(state) {
				return state[this.storeModule].data;
			},
		}),
		items() {
			const self = this;
			let items = Object.values(self.collection);
			if (self.include && self.include.length) {
				items = items.filter((a) => self.include.includes(a.id));
			}
			if (self.exclude && self.exclude.length) {
				items = items.filter((a) => !self.exclude.includes(a.id));
			}
			return items;
		},
		selected() {
			var self = this;
			if (this.multiple) {
				return self.value.map((t) => self.collection[t]);
			} else {
				return self.collection[self.value];
			}
		},
	},
	created() {
		this.$store.dispatch(`${this.storeModule}/openDBChannel`);
	},
	destroyed() {
		this.$store.dispatch(`${this.storeModule}/closeDBChannel`);
	},
	methods: {
		selectOrCreate(selected) {
			if (!selected || !selected.length) {
				return;
			}
			var latest;
			if (this.multiple) {
				latest = selected[selected.length - 1];
			} else {
				latest = selected;
			}

			if (!this.collection[latest]) {
				this.latest = latest;
				this.dialog = true;
			} else {
				this.$emit("input", selected);
			}
		},
		confirm() {
			var self = this;
			var item = {
				[self.itemText]: self.latest,
				...self.defaultValues,
			};
			self.$store
				.dispatch(`${self.storeModule}/insert`, item)
				.then((res) => {
					var value = res;
					if (self.multiple) {
						value = [...self.value, res];
					}
					self.$emit("input", value);
					self.$emit("created", res);
					self.close();
				});
		},
		close() {
			var self = this;
			self.latest = false;
			self.dialog = false;
		},
	},
};
//
</script>
"
